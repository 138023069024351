<template>
  <div class="payment-choice__item payment-choice__item_paypal">
    <div id="paypal-button-container" class="payment-option payment-option_paypal"></div>
  </div>
</template>

<script>
import { loadScript } from '@/services/helpers.js'
import LandingServices from '@/services/LandingServices'
const paypalScript = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_ID}&vault=true&intent=subscription`
export default {
  data() {
    return {
      buttonRendered: false
    }
  },
  methods: {
    loadPaypal() {
      if (!this.buttonRendered) {
        loadScript(paypalScript).then(() => {
          this.createButton()
        })
      } else {
        this.$emit('toggleSpinner', false)
      }
    },
    createButton() {
      const initMetrikaGoal = this.initMetrikaGoal;
      // eslint-disable-next-line no-undef
      paypal.Buttons({
        locale: 'en_US',
        style: stylesPaypal,
        async createSubscription (data, actions) {
          initMetrikaGoal()
          await window.fbq('track', 'Subscribe')
          return actions.subscription.create({
            'plan_id': process.env.VUE_APP_PAYPAL_PRODUCT_ID
          });          
        },
        onApprove(data) {
          LandingServices.checkoutPaypal({
            subscription_id: data.subscriptionID,
            user_id: window.user_id
          }).then(() => {
            window.location.href = `${process.env.VUE_APP_LANDING_URL}register/${window.user_id}`
          }).catch(console.error)
        }
      }).render('#paypal-button-container').then(() => {
        this.buttonRendered = true
        // paypal bypass to prevent double click on the button,
        setTimeout(() => {
          this.$emit('toggleSpinner', false)
        }, 200)
      })
    },
    initMetrikaGoal() {
      setTimeout(() => {
        if (window.ym) {
          window.ym(process.env.VUE_APP_YANDEX_ID,'reachGoal','paypal')
        } else {
          this.initMetrikaGoal()
        }
      }, 100);
    }
  }
}

const stylesPaypal = {
    layout: 'horizontal',
    shape: 'pill',
    height: 55,
    color: 'gold',
    tagline: false,
    label: 'paypal'  
}
</script>